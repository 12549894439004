<template>
    <div id="sectors">
      <section id="hero">
        <div id="hero-content">
          <div id="hero-text">
            <h1>
              Reset's Sectors
            </h1>
          </div>
          <!-- <button @click="heroButton" class="shadow">Learn more</button> -->
        </div>
      </section>

      <section id="prisons" class="service fade-in">
        <div class="content">
          <h2>ReSeT for Prisons</h2>
          <p>ReSeT for the Criminal Justice System can be used in prisons or in probation.
            It is a 6-week course with a workbook and films.
            The course can be delivered without external facilitators and we can offer the training to
            officers or professionals within the jail to deliver the course successfully.</p><p>
            For the prisons version we offer a DVD when online access to the films cannot be accessed.</p><p>
            Feedback from our course in jails has been universally positive and has been researched by
            Dr Lucy Willmott from Cambridge Unviersity highlighting the beneficial results.
            Contact us for the costs for your service.</p>
            <router-link class="shadow invert button" to="/signin">Sign In</router-link>
        </div>
        <div class="content">
            <div class="image-container">
              <img class="image-zoom" src="../assets/imgs/services/prisons.jpg" alt="prisons">
            </div>
        </div>
      </section>

      <section id="workplace" class="service fade-in">
        <div class="content">
            <h2>ReSeT for Workplace</h2>
          <p>This simple course offers online films and notes to give co-workers the skills to face into
            challenging conversations.</p><p>
            Many organisations, particularly EDI and HR departments, contact us because they want to
            change their approaches to dealing with difficulty, issues and challenges. It is essential that
            all co-workers understand restorative ways of working in all their interactions with
            colleagues.</p><p>
            The ReSeT course is an excellent way of giving all colleagues these tools and encouraging
            direct conversation as an expectation of the workplace and how to positively and
            respectfully engage with each other. This is particularly effective in large organisations that
            want to reach all their employees. In one organisation we worked with their grievances
            reduced by 60% in the first year after introducing restorative ways of working and the ReSeT
            tools.</p><p>
            Contact us for the costs for your workplace.
          </p>
          <router-link class="shadow button" to="/signin">Sign In</router-link>
        </div>
        <div class="content">
          <div class="image-container">
            <img class="image-zoom" src="../assets/imgs/services/workplace.jpg" alt="workplace">
          </div>
        </div>
      </section>

      <section id="education" class="service fade-in">
        <div class="content">
            <h2>ReSeT for Education</h2>
            <p>Many schools and higher education establishments are introducing restorative practices to
                support the working environment. Indeed, in some higher education establishments
                restorative/relational skills are being taught as part of induction. </p><p>The evidence for
                improving the culture and environment has been extensively researched and shows marked
                improvement in absences and relationships. However, it requires a commitment across the
                whole establishment. ReSeT is a way of offering the tools to everyone.</p><p> It can be a part of
                the PSHE classes. It is a short course of workbooks and films that are all downloadable from
                the internet. Teachers can download the training guide as well.
                Contact us for the costs for your establishment.
              </p>
              <router-link class="shadow invert button" to="/signin">Sign In</router-link>
        </div>
        <div class="content">
          <div class="image-container">
            <img class="image-zoom" src="../assets/imgs/services/education.jpg" alt="education">
          </div>
        </div>
      </section>
  
      <section id="team-section" class="fade-in">
        <h2>Meet the team</h2>
        <div id="team">
          <div class="card people" v-for="person in people" :key="person.id" @click="personBio">
            <div class="image-container">
              <img class="image-zoom" :src="person.img" :alt="person.name">
            </div>
            <h6 class="name">{{person.name}}</h6>
            <p class="position">{{person.position}}</p>
          </div>
        </div>
      </section>
  
      <section id="partners-section" class="fade-in">
        <h2>Our Partners</h2>
        <div id="partners">
          <div class="logo">
            <img src="../assets/imgs/partner-logos/Pact.png" alt="">
          </div>
          <div class="logo">
            <img src="../assets/imgs/partner-logos/cja.svg" alt="">
          </div>
          <div class="logo">
            <img src="../assets/imgs/partner-logos/porticus.svg" alt="">
          </div>
        </div>
      </section>
      <modal class="person-details" v-if="showBio" :person="selectedPerson" @close="closeModal"/>
    </div>
  </template>
  
  <script>
  // import Modal from '@/components/Modal.vue';
  import { ScrollTrigger } from "gsap/ScrollTrigger";
  import modal from "../components/Modal.vue"
  
  export default {
    name: 'Home',
    components: {
      modal,
    },
    props: [
      'fadeIn',
      'people'
    ],
    data() {
      return {
        showBio: false,
        selectedPerson: {}
      }
    },
    mounted(){
        this.fadeIn()
        this.$root.imageZoom()
        setTimeout(() => {
          ScrollTrigger.refresh()
        }, 1000)
    },
    methods: {
      heroButton(){
        let subHero = document.getElementById("sub-hero")
        subHero.scrollIntoView({behavior: "smooth"});
      },
      personBio(e){
        this.showBio = true;
        document.body.style.overflow = "hidden"
        let peopleArray = Object.values(document.querySelectorAll('.people'))
        let index = peopleArray.indexOf(e.currentTarget)
        this.selectedPerson = Object.values(this.people)[index]
      },
      closeModal() {
        this.showBio = false;
        document.body.style.overflow = "initial"
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  #sectors{
    outline: none;
  }
  #hero {
    @include for-phone-only(){
      flex-direction: column;
      padding: 1em 0;
      align-items: center;
      height: auto;
    }
    padding: 4em 8% 0 8%;
    display: flex;
    height: 25vh;
    overflow: hidden;
  
    img {
      @include for-phone-only(){
        max-width: 100%;
        align-self: center;
      }
      max-width: 85%;
      max-height: 100%;
      align-self: flex-start;
    }
  
    #hero-content {
    @include for-phone-only(){
      order: initial;
      margin: 1em 0 0 0;
      width: 96%;
  
    }
      order: -1;
      margin: 0 -25em 3em 0;
  
      #hero-text {
        @include for-phone-only(){
        padding: 2em 1em;
        }
        padding: 3em 0;
        
  
        h1 {
          @include for-phone-only(){
            white-space: initial;
          }
          white-space: nowrap;
        }
  
        h5 {
          color: #fff;
        }
      }
  
      button {
        @include for-phone-only(){
        margin: 1em auto;
        }
        padding: 1em 2.5em;
        margin: 1em 0;
        border-radius: 25px;
        border: none;
        color: #ffffff;
        background: #2A3854;
      }
    }
  }
  .service {
    @include for-phone-only(){
      flex-direction: column-reverse;
        padding: 2em 1em;
    }
    background: #2A3854;
    color: #fff;
    display: flex;
    align-items: center;
    .content {
        
      @include for-phone-only(){
      width: 100%;
      margin: 1em 0 0 0;
      }

      &:first-child {
        @include for-phone-only(){
          width: 100%;
          padding: 1em 8%;
        }
        width: 50%;
        padding: 4em 8%;
      }
      
      h2 {
        color: #fff;
      }
  
      p {
        color: #fff;
      }

      a {
        margin: 2em 0 0 0;
        display: inline-block;
      }

      .image-container {
        width: 100%;
        overflow: hidden;
      }

      img {
        width: 100%;
      }
    }

    &#workplace {
        background: $white;
        color: $blue;

        .content{
            @include for-phone-only(){
              width: 100%;
              margin: 1em 0 0 0;
            }
            &:first-child {
              @include for-phone-only(){
                width: 100%;
                padding: 1em 8%;
              }
              width: 50%;
              padding: 4em 8%;
            }
        
        
            h2 {
              color: $blue;
            }
        
            p {
              color: $blue;
            }
          }
    }
  }
  #team-section{
    @include for-phone-only(){
      padding: 2em 1em;
    }
    padding: 5em 8% 3em 8%;
  
    h2 {
      @include for-phone-only(){
        margin: 0 0 35px 0;
      }
    }
  
    #team {
      display: flex;
      flex-wrap: wrap;
  
      .card {
        @include for-phone-only(){
          width: 100%;
          margin: 0 0 1em 0;
          padding: 0;
        }
        width: calc(25% - 4em);
        // margin: 0 1em 0 0;
        padding: 2em;
        text-align: center;
        cursor: pointer;
  
        .image-container {
          width: 50%;
          border-radius: 50%;
          margin: 0 auto 2em auto;
          overflow: hidden;
        }
  
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  #partners-section{
    @include for-phone-only(){
      padding: 2em 1em;
    }
    padding: 3em 8%;
  
    #partners{
      @include for-phone-only(){
        margin: 0 0 1em 0;
        padding: 0;
      }
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .logo {
        @include for-phone-only(){
          width: 100%;
          margin: 0 0 1em 0;
          padding: 0;
        }
        width: 30%;
        padding: 1em;
        display: flex; 
        align-items: center; 
        justify-content: center;
  
        img {
          @include for-phone-only(){
            width: 80%;
          }
          width: 60%;
        }
      }
    }
  }
  </style>
  