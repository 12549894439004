
  
<template>
  <div id="modal" ref="lightBox" @click="lightBoxClose">
    <div class="modal details">
      <button class="close shadow" @click="close">
        <svg class="x-button" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="20px" width="20px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
          <g><path d="M2.3,50c-0.6,0-1.2-0.2-1.6-0.7c-0.9-0.9-0.9-2.3,0-3.2L46.1,0.7c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2L3.9,49.3C3.4,49.8,2.9,50,2.3,50z"/></g>
          <g><path d="M47.7,50c-0.6,0-1.2-0.2-1.6-0.7L0.7,3.9c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0l45.5,45.5c0.9,0.9,0.9,2.3,0,3.2C48.9,49.8,48.3,50,47.7,50z"/></g>
        </svg>
      </button>

      <section class="modal-body" id="modalDescription">
        <p class="name">{{person.name}}</p>
        <p class="position">{{person.position}}</p>
        <p class="bio">{{person.bio}}</p>
      </section>
    </div>
  </div>
</template>
  
<script>
export default {
    name: 'Modal',
    props:[
		'person',
	],
    methods: {

      lightBoxClose(e){
        if (e.target === this.$refs.lightBox){
          this.close();
        }
      },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
  
  #modal {
    background: #00000060;
    box-shadow: 1px 1px 5px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    .details{
      @include for-phone-only(){
      width: calc(95% - 4em);
      padding: 2em;
      }
      padding: 4.5em;
      width: 45%;
      max-height: 75vh;
    }
    .close{
      @include for-phone-only(){
      top: 20px;
      right: 20px;
      }
      position: absolute;
      padding: 0.7em;
      border: solid 1px #000;
      border-radius: 2em;
      top: 30px;
      right: 30px;
      z-index: 25;
      margin: 0;
      background: $white;
    }
    .x-button{
      vertical-align: bottom;
    }
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: calc(100% - 40px);
    overflow-y: scroll;
    .name, .position{
      margin: 0 0 1em 0;
      font-weight: 700;
    }
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>