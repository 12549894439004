<template>
	<div id="register">
		<div class="modal">
			<button @click="signIn" id="googleLogin" class="shadow">
				<svg enable-background="new 0 0 533.5 544.3" version="1.1" width="20px" height="20px" viewBox="0 0 533.5 544.3" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:ns="&amp;ns_sfw;" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
					<path d="m533.5 278.4c0-18.5-1.5-37.1-4.7-55.3h-256.7v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" style="fill:#4285f4"/>
					<path d="m272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3h-90.5v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" style="fill:#34a853"/>
					<path d="M119.3,324.3c-11.4-33.8-11.4-70.4,0-104.2V150H28.9c-38.6,76.9-38.6,167.5,0,244.4L119.3,324.3z" style="fill:#fbbc04"/>
					<path d="m272.1 107.7c38.8-0.6 76.3 14 104.4 40.8l77.7-77.7c-49.2-46.2-114.5-71.6-182.1-70.8-102.9 0-197 58-243.2 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" style="fill:#ea4335"/>
				</svg>
				Sign in with Google</button>
			<form class="register-form" @submit.prevent="registerWithEmailAndPassword">
				<fieldset>
					<div>
						<label class="label" for="name">User name</label>
						<input type="text" name="name" id="name" v-model="name" required>
					</div>
					<div>
						<label class="label" for="email">Email address</label>
						<input type="email" name="email" id="email" v-model="email" required>
					</div>
					<div>
						<label class="label" for="textarea">Password</label>
						<input type="password" name="password" id="password" v-model="password" required
							@keyup.enter="registerWithEmailAndPassword">
					</div>
					<div>
						<button class="shadow signup-button">Sign Up</button>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</template>

<script>
	import {GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
	import {firebaseAuth} from '../firebase'

	export default {
		data() {
			return {
				name: null,
				email: null,
				password: null
			}
		},
		methods: {
			signIn() {
				const provider = new GoogleAuthProvider()

				return signInWithPopup(firebaseAuth, provider)
					.then(() => this.$router.replace({
						name: 'Account'
					}))
					.catch(error => {
						switch(error.code){
							case 'auth/user-not-found':
								alert("User not found")
								break
							case 'auth/wrong-password':
								alert("Wrong password")
								break
							default:
								alert("Something went wrong :(")
						}
					})
			},
			registerWithEmailAndPassword() {
					return createUserWithEmailAndPassword(firebaseAuth, this.email, this.password)
					.then(() => {
						const user = firebaseAuth.currentUser;
						sendEmailVerification(user);
						this.$router.push('/account')
					})
					.catch(error => {
						switch(error.code){
							case 'auth/email-already-exists':
								alert("The provided email is already in use by an existing user.")
								break
							default:
								alert("Something went wrong :(")
						}
					})
			},
		}
	}
</script>

<style lang="scss">
#register {
	@include for-phone-only(){
		padding: 1em 0;
	}
	padding: 3em 0;
	align-items: center;
	justify-content: center;
	height: 100%;
	.signup-button{
		width: 30%;
		padding: 1em;
	}
}
</style>