<template>
    <div id="account">
        <section id="hero">
          <div id="hero-content">
            <div id="hero-text">
              <h1>
                Available Downloads
              </h1>
            </div>
            <div v-if="isLoading">Loading account information...</div>
            <!-- <PriceSelection v-else-if="!subscription" /> -->
            <!-- <SubscribedAccount v-else :subscription="subscription"/> -->
          </div>
        </section>
    </div>
</template>

<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import PriceSelection from "@/components/PriceSelection";
// import SubscribedAccount from "@/components/SubscribedAccount";
// import { firebaseAuth } from "@/firebase";
// import {
//     getFirestore,
//     collection,
//     query,
//     where,
//     getDocs,
// } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default {
    // components: {
    //     PriceSelection,
    //     SubscribedAccount
    // },
    data() {
      return {
          auth: getAuth(),
          subscription: null,
      };
        // return {
        //     isLoading: false,
        //     subscription: null,
        // };
    },
    props: [
      'fadeIn',
    ],
    mounted() {
        this.fetchUser()
        // this.fetchSubscription()
        this.fadeIn()
        setTimeout(() => {
          ScrollTrigger.refresh()
        }, 1000)
    },
    methods: {
        async fetchUser() {

          
            if (this.auth.currentUser) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // ...
            const uid = this.auth.currentUser.uid;

            if(uid == 'Eceeyv1P3OdacnDwMlDxQiHlI543') {
              var html = '<a>DOWNLOADABLE CONTENT HERE</a>'
            setTimeout(function() {
              document.getElementById('hero-content').innerHTML += html
            }, 600)
            }
          } 
          
         
          
        },
        // async fetchSubscription() {
        //     this.isLoading = true;
        //     const db = getFirestore();
        //     const subsRef = collection(db, "customers", firebaseAuth.currentUser.uid, "subscriptions");
        //     const subsQuery = query(subsRef, where("status", "in", ["trialing", "active", "past_due", "unpaid"]));
        //     this.subscription = await getDocs(subsQuery)
        //     .then((sub) => sub.docs.length > 0 ? sub.docs[0].data() : null);
        //     this.isLoading = false;
        // },
    },
};
</script>

<style lang="scss" scoped>
  #account {
    align-items: center;
    justify-content: center;
  }
  #hero {
    @include for-phone-only(){
      flex-direction: column;
      padding: 1em 0;
      align-items: center;
    }
    padding: 4em 8% 0 8%;
    min-height: 60vh;
    display: flex;
    overflow: hidden;
  
    img {
    @include for-phone-only(){
      max-width: 100%;
      align-self: center;
    }
      max-width: 85%;
      max-height: 100%;
      align-self: flex-start;
    }
  
    #hero-content {
      @include for-phone-only(){
        order: initial;
        margin: 1em 0 0 0;
        width: 96%;
      }
      order: -1;
      margin: 0 auto;

      div {
        margin: 0 0 2em 0;
        div {
          margin: 0 0 2em 0;
          
        }
      }
  
      #hero-text {
        @include for-phone-only(){
        padding: 2em 1em;
        }
        padding: 1em 0;
        
  
        h1 {
          @include for-phone-only(){
            white-space: initial;
          }
          white-space: nowrap;
        }
  
        h5 {
          color: #fff;
        }
      }
  
      button {
        @include for-phone-only(){
        margin: 1em auto;
        }
        padding: 1em 2.5em;
        margin: 1em 0;
        border-radius: 25px;
        border: none;
        color: #ffffff;
        background: #2A3854;
      }
    }

    .price {
        margin: 2em 0;
    }
  }
  .service {
    @include for-phone-only(){
    flex-direction: column;
        padding: 2em 1em;
    }
    padding: 4em 8%;
    background: #2A3854;
    color: #fff;
    display: flex;
    align-items: center;
    .content {
        
      @include for-phone-only(){
      width: 100%;
      margin: 1em 0 0 0;
      }

      &:first-child {
        width: 50%;
        padding-right: 4em;
      }
      
  
      h2 {
        color: #fff;
      }
  
      p {
        color: #fff;
      }

      a {
        margin: 2em 0 0 0;
        display: inline-block;
      }
    }

    &#workplace {
        background: $white;
        color: $blue;

        .content{
            @include for-phone-only(){
            width: 100%;
            margin: 1em 0 0 0;
            }
            &:first-child {
                width: 50%;
            }
        
        
            h2 {
              color: $blue;
            }
        
            p {
              color: $blue;
            }
          }
    }
  }
  #team-section{
    @include for-phone-only(){
      padding: 2em 1em;
    }
    padding: 5em 8% 3em 8%;
  
    h2 {
      @include for-phone-only(){
        margin: 0 0 35px 0;
      }
    }
  
    #team {
      display: flex;
      flex-wrap: wrap;
  
      .card {
        @include for-phone-only(){
          width: 100%;
          margin: 0 0 1em 0;
          padding: 0;
        }
        width: calc(25% - 4em);
        // margin: 0 1em 0 0;
        padding: 2em;
        text-align: center;
        cursor: pointer;
  
        img {
          width: 50%;
          border-radius: 50%;
          margin: 0 0 2em 0;
        }
      }
    }
  }
  #partners-section{
    @include for-phone-only(){
      padding: 2em 1em;
    }
    padding: 3em 8%;
  
    #partners{
      @include for-phone-only(){
        margin: 0 0 1em 0;
        padding: 0;
      }
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .logo {
        @include for-phone-only(){
          width: 100%;
          margin: 0 0 1em 0;
          padding: 0;
        }
        width: 30%;
        padding: 1em;
        display: flex; 
        align-items: center; 
        justify-content: center;
  
        img {
          @include for-phone-only(){
            width: 80%;
          }
          width: 60%;
        }
      }
    }
  }
  </style>
  