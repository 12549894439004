<template>
  <div id="password-reset">
		<div class="modal">
      <form class="password-reset-form" @submit.prevent="sendResetEmail">
          <fieldset>
            <div>
              <label class="label" for="email">email address</label>
              <input type="email" name="email" id="email" v-model="email" required
              @keyup.enter="login">
            </div>
            <div>
              <button class="shadow">send a password reset link</button>
            </div>
          </fieldset>
        </form>
      </div>
  </div>
</template>

<script>
  // import { firebaseAuth } from '../firebase';
  import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      error: null,
      emailSending: false,
    };
  },

  methods:{
    sendResetEmail() {
      if (!this.email) {
        this.error = "Please type in a valid email address.";
        return;
      }
      this.error = null;
      this.emailSending = true;
      console.log(this.emailSending)
      // const app = initializeApp(environment.firebaseConfig);
      const auth = getAuth();
      return sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.emailSending = false;
        })
        .catch((error) => {
          // const errorCode = error.code;
          this.emailSending = false;
          this.error = error.message;
        });
    }
  }
}
</script>

<style lang="scss">
#password-reset {
	@include for-phone-only(){
	padding: 1em 0;
	}
	padding: 3em 0;
	align-items: center;
	justify-content: center;
	height: 100%;
}

</style>