<template>
    <div id="contact">
        <div class="modal">
            <form class="contact-form" @submit.prevent="submit">
                <h3 id="success-message">Thank you for contacting us.</h3>
                <fieldset>
                    <div>
                        <label class="label required" for="name">Name</label>
                        <p class="error-message" v-if="!validName && name.allowValidation">A name is required</p>
                        <input type="text" name="name" id="name" required v-model="name.value" v-on:blur="name.allowValidation = true" v-on:focus="name.allowValidation = false">
                    </div>
                    <div>
                        <label class="label required" for="email">Email</label>
                        <p class="error-message" v-if="!validEmail && email.allowValidation">Please enter a valid email address.</p>
                        <input type="email" name="email" id="email" required :class="{ email , error: !email.valid }" v-model="email.value" v-on:blur="email.allowValidation = true" v-on:focus="email.allowValidation = false">
                    </div>
                    <div>
                        <label class="label" for="email">Subject</label>
                        <!-- <p id="subject"  class="subject">{{subject.value}}</p> -->
                        <input type="text" name="subject" id="subject" required :class="{ email , error: !email.valid }" v-model="subject.value">
                    </div>
                    <div>
                        <label class="label required" for="textarea">Message</label>
                        <textarea class="message" name="message" id="textarea" required v-model="message.value" v-on:blur="message.allowValidation = true" v-on:focus="message.allowValidation = false"></textarea>
                    </div>
                    <div>
                        <div class="g-recaptcha" data-sitekey="6Ld85MIjAAAAAFSRZ_8PhOBLGbvB3OMduNaUSN_c"></div>
                        <button type="submit">Send  ➔</button>
                        <p class="confirmationMsg" v-if="submitted">Your message has been sent, we will get back to you soon.</p>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
    data(){
        return{
            name: {
                value: "",
                allowValidation: false
            },
            email: {
                value: "",
                allowValidation: false
            },
            subject: {
                value: ""
            },
            message: {
                value: ``,
                allowValidation: false
            },
            validForm: false,
            submitted: false
        }
    },
    props: [
        'fadeIn',
    ],
    computed: {
        validName (){
          return this.name.value ? true : false
        },
        validEmail (){
          return this.isEmail(this.email.value)
        },
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      document.head.appendChild(recaptchaScript)

      this.fadeIn();
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 1000)
    },
    // Regular expression from W3C HTML5.2 input specification:
    // https://www.w3.org/TR/html/sec-forms.html#email-state-typeemail
    methods: {
        
        // submit form handler
        async submit(e) {
            try {
                emailjs.sendForm('service_tevzgex', 'template_tstmdfa', e.target,'Mig0mq9mdJcjxUu-5', {
                name: this.name,
                email: this.email,
                subject: this.subject,
                message: this.message
                })

            } catch(error) {
                console.log({error})
            }
            // Reset form field
            this.name = ''
            this.email = ''
            this.subject = ''
            this.message = ''
            this.validForm = this.validName && this.validEmail;
            document.getElementById('success-message').classList.add('active')
        },
        // check for valid email adress
        isEmail: function(value) {
            var emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailRegExp.test(value);
        }
    }
}
</script>

<style lang="scss">

#contact {
    @include for-phone-only(){
        padding: 1em 0;
    }
    padding: 3em 0;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.contact-form {
    @include for-phone-only(){
        margin: 20px auto;
    }
    margin: 50px auto;

    #success-message {
        display: none;
        color: #5D399E;
    
        &.active {
            display: block;
        }
    }
    
    fieldset {
        border: none;
    }
    div {
        position: relative;
        margin: 20px 0;
    }
    .label {
        color: #2c3e50;
        margin-bottom: 10px;
        display: inline-block;
        line-height: 2rem;
        width: 50%;
    }
    input{
        color: #2c3e50;
        display: block;
        width: 94%;
        appearance: none;
        padding: 10px 3%;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid #cfd9db;
        background-color: #ffffff;
    }
    .required::after{
        margin: 0 0 0 0.3em;
        font-size: 0.8em;
        width: 4px;
        height: 4px;
        color: #b71c1c;
        content: '*';
    }
    input[type="text"]:focus,
    input[type="email"]:focus,
    textarea:focus{
        outline: none;
        border-color: $red;
        box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.1);
    }
    textarea{
        color: #2b3e51;
        display: block;
        width: 94%;
        appearance: none;
        padding: 10px 3%;
        border: 1px solid #cfd9db;
        background-color: #ffffff;
        border-radius: 0.3rem;
        min-height: 120px;
        resize: vertical;
        overflow: auto;
        font-family: "Gantari", helvetica, sans-serif;
    }
    .error-message {
        display: inline-block;
        margin: 0px;
        padding: 0;
        color: #ff0000;
        font-size: .7rem;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .counter{
        font-size: 0.7rem;
        float: right;
        line-height: 2rem;
        color: #94aab0;
    }
    .confirmationMsg{
        display: inline-block;
        margin: 0px;
        padding: 0;
        font-size: .7rem;
        margin: 0 0 0 1rem;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2b3e51;
    }
    .signin-button{
        width: 30%;
        padding: 1em;
    }
}

    .contact-form input[type="submit"] {
        font-family: inherit;
        width: initial;
        border: none;
        display: inline-block;
        background: #2c3e50;
        padding: 8px 20px;
        color: #ffffff;
        border-radius: 3rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    
</style>