<template>
    <div id="nav" class="fade-in">
        <ul v-if="!isMobile">
            <li>
                <router-link class="shadow" to="/sectors">Sectors</router-link>
            </li>
            <li v-if="!currUser">
                <router-link class="shadow" to="/signin" id="signin">Sign In</router-link>
            </li>
            <li v-if="currUser">
                <router-link class="shadow" to="/account">My account</router-link>
            </li>
            <li v-if="currUser">
                <button class="shadow" @click="logOut">Log out</button>
            </li>
            <li>
                <router-link class="shadow" to="/contact">Contact</router-link>
            </li>
        </ul>
        <div v-if="isMobile" ref="burgerButton" id="burgerButton" @click="burgerMenu" >
            <div class="topBar" ref="topBar"></div>
            <div class="middleBar" ref="middleBar"></div>
            <div class="bottomBar" ref="bottomBar"></div>
        </div>
    </div>
    <ul id="burgerMenu" ref="burgerMenu" v-if="isMobile && showBurgerMenu">
            <li>
                <router-link class="shadow" to="/" @click="burgerMenu">Home</router-link>
            </li>

            <li>
                <router-link class="shadow" to="/sectors" @click="burgerMenu">Sectors</router-link>
            </li>
            <li v-if="!currUser">
                <router-link class="shadow" to="/signin" @click="burgerMenu">Sign In</router-link>
            </li>
            <li v-if="currUser">
                <router-link class="shadow" to="/account" @click="burgerMenu">My account</router-link>
            </li>
            <li v-if="currUser">
                <button class="shadow" @click="logOut(); burgerMenu();">Log out</button>
            </li>
            <li>
                <router-link class="shadow" to="/contact" @click="burgerMenu">Contact</router-link>
            </li>
        </ul>
</template>

<script>
import {gsap} from 'gsap';
import { signOut } from "firebase/auth";
import { firebaseAuth } from "@/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { nextTick } from '@vue/runtime-core';

var loggedIn;
const auth = getAuth();

export default {
    data() {
        return {
            currUser: loggedIn,
            showBurgerMenu: false
        }
    },
    props: [
        'isMobile'
    ],
    methods: {
        // Fetch user to check if logged in
        authState: function() {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is logged in
                    this.currUser = user
                } else {
                    // User is signed out
                    this.currUser = false
                }
            })
        },
        // Log out then send user to Sign in page
        logOut() {
            signOut(firebaseAuth).then(() =>
                this.$router.replace({ name: "SignIn" })
            )
        },
        async burgerMenu(){
            if(!this.showBurgerMenu){
                this.showBurgerMenu = !this.showBurgerMenu;
                await nextTick()
                let burgerMenu = this.$refs.burgerMenu;
                let topBun = this.$refs.topBar;
                let middleBun = this.$refs.middleBar;
                let bottomBun = this.$refs.bottomBar;
                gsap.to(burgerMenu, {duration:.6, left:0, ease: "power3.out"})
                gsap.to(topBun, {duration: .4, rotate: 45, y:"8px"})
                gsap.to(middleBun, {duration: .4, x: -100, opacity:0})
                gsap.to(bottomBun, {duration: .4, rotate: -45, y:"-8px"})
                document.body.style.overflow = "hidden";
            }else{
                let burgerMenu = this.$refs.burgerMenu;
                let topBun = this.$refs.topBar;
                let middleBun = this.$refs.middleBar;
                let bottomBun = this.$refs.bottomBar;
                gsap.to(topBun, {duration: .4, rotate: 0, y:0})
                gsap.to(middleBun, {duration: .4, x: 0, opacity:1})
                gsap.to(bottomBun, {duration: .4, rotate: 0, y:0})
                gsap.to(burgerMenu, {duration:.6, left:"110vw", ease: "power3.out", onComplete: ()=>{this.showBurgerMenu = !this.showBurgerMenu}}) 
                document.body.style.overflow = "initial";
            }
        },
    },
    // Sometimes the onAuthStateChanged function returns the user as null so this makes our data export once JS is loaded
    beforeMount(){
        this.authState()
    }
}
</script>

<style lang="scss" scoped>
ul{
    @include for-phone-only(){
    margin: 0;
    }
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      color: #2c3e50;
      padding: 7px 15px;
      margin: 0 0.6em;
      border: solid 1px #2A3854;
      border-radius: 25px;

      &.router-link-exact-active {
        color: #ffffff;
        background: #2A3854;
      }
    }

    button {
      margin: 0 1em;
    }
}
#burgerButton{
    z-index: 20;
    .topBar, .middleBar, .bottomBar {
        width: 30px;
        height: 2px;
        border-radius: 2px;
        background-color: #333;
        margin: 6px 0;
    }

}
#burgerMenu{
  padding: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 110vw;
  z-index: 10;
  background-color: $white;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;

  li{
    text-align: center;
    width: 40%;
    margin: 0 0 3em 0;
    button{
        width: calc(100% - 1.2em);
      margin: 0 0.6em;
    }
  }
//   a{
//       border: solid 1px $white;
//       color: $white;
//     &.router-link-exact-active {
//         color: $blue;
//         background: $white;
//     }
//   }
}
</style>