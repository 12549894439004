<template>
	<div id="sign-in">
		<div class="modal">
			<button @click="signIn" id="googleLogin" class="shadow">
				<svg enable-background="new 0 0 533.5 544.3" version="1.1" width="20px" height="20px" viewBox="0 0 533.5 544.3" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:ns="&amp;ns_sfw;" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
					<path d="m533.5 278.4c0-18.5-1.5-37.1-4.7-55.3h-256.7v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" style="fill:#4285f4"/>
					<path d="m272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3h-90.5v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" style="fill:#34a853"/>
					<path d="M119.3,324.3c-11.4-33.8-11.4-70.4,0-104.2V150H28.9c-38.6,76.9-38.6,167.5,0,244.4L119.3,324.3z" style="fill:#fbbc04"/>
					<path d="m272.1 107.7c38.8-0.6 76.3 14 104.4 40.8l77.7-77.7c-49.2-46.2-114.5-71.6-182.1-70.8-102.9 0-197 58-243.2 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" style="fill:#ea4335"/>
				</svg>
				Sign in with Google</button>
			<form class="login-form" @submit.prevent="loginWithEmailAndPassword">
				<fieldset>
					<div>
						<label class="label" for="email">User name</label>
						<input type="email" name="email" id="email" v-model="email" required>
					</div>
					<div>
						<label class="label" for="textarea">Password</label>
						<input type="password" name="password" id="password" v-model="password" required
						@keyup.enter="login">
					</div>
					<div>
						<button class="signin-button shadow">Sign in</button>
					</div>
					<div id="forgotPassword">forgot your password?
						<router-link to="forgot-password" id="resetPassword">reset password</router-link>
					</div>
					<div> Don't have an account?
						<router-link to="/signup" id="register">Register now</router-link>
					</div>
				</fieldset>
			</form>
		</div>
		
	</div>
</template>

<script>
import {GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword} from 'firebase/auth'
import {firebaseAuth} from '../firebase'
import { ScrollTrigger } from "gsap/ScrollTrigger"

export default {
	data() {
		return {
			email: null,
			password: null
		}
	},
	props: [
		'fadeIn',
	],
	mounted(){
      this.fadeIn();
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 1000)
	},
	methods: {
		signIn() {
			const provider = new GoogleAuthProvider()
			
			return signInWithPopup(firebaseAuth, provider)
			.then(() => this.$router.replace({
				name: 'Account'
			}))
			.catch(error => {
				switch(error.code){
					case 'auth/user-not-found':
					alert("User not found")
					break
					case 'auth/wrong-password':
					alert("Wrong password")
					break
					default:
					alert("Something went wrong :(")
				}
			})
		},
		loginWithEmailAndPassword() {
			return signInWithEmailAndPassword(firebaseAuth, this.email, this.password)
			.then(() => 
			this.$router.push('/account')
			)
			.catch(error => {
				switch(error.code){
					case 'auth/user-not-found':
					alert("User not found")
					break
					case 'auth/wrong-password':
					alert("Wrong password")
					break
					default:
					alert("Something went wrong :(")
				}
			})
		},
	}
}
</script>

<style lang="scss">
#sign-in {
	@include for-phone-only(){
	padding: 1em 0;
	}
	padding: 3em 0;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.modal{
	@include for-phone-only(){
		width: 80%;
		padding: 15px 1.5em;
	}
	width: 40%;
	margin: auto;
	padding: 15px 5em;
	background-color: #fff;
	border-radius: 3em;
	box-shadow: 1px 1px 58px 11px rgba(0,0,0,5%);
	-webkit-box-shadow: 1px 1px 58px 11px rgba(0,0,0,5%);
	-moz-box-shadow: 1px 1px 58px 11px rgba(0,0,0,5%);
	#googleLogin{
		@include for-phone-only(){
			margin: 20px auto;
		}
		padding: 5px 20px 5px 7px;
		svg{
			vertical-align: middle;
			margin: 0 1em 0 0 ;
			background: $white;
			padding: 8px;
			border-radius: 20px;
		}
	}
	.login-form, .register-form, .password-reset-form {
		@include for-phone-only(){
			margin: 20px auto;
		}
		margin: 50px auto;
		fieldset {
			border: none;
		}
		div {
			position: relative;
			margin: 20px 0;
		}
		.label {
			color: #2c3e50;
			margin-bottom: 10px;
			display: inline-block;
			line-height: 2rem;
			width: 50%;
		}
		input{
			color: #2c3e50;
			display: block;
			width: 94%;
			appearance: none;
			padding: 10px 3%;
			border-top: none;
			border-right: none;
			border-left: none;
			border-bottom: 1px solid #cfd9db;
			background-color: #ffffff;
		}
		input[type="text"]:focus,
		input[type="email"]:focus,
		input[type="password"]:focus{
			outline: none;
			border-color: $red;
			box-shadow: 0px 5px 15px 3px rgba(0,0,0,0.15);
		}
		.signin-button{
			width: 30%;
			padding: 1em;
		}
		#resetPassword{
			@include for-phone-only(){
				margin: 0.5em 0 0 0;
				padding: 3px 30px;
			}
			display: inline-block;
      color: #2c3e50;
      padding: 3px 15px;
			text-align: right;
      margin: 0 0 0 2em;
      border: solid 1px #2A3854;
      border-radius: 25px;
		}
		#register{
			@include for-phone-only(){
				margin: 0.5em 0 0 0;
				padding: 3px 30px;
			}
			display: inline-block;
      color: #2c3e50;
      padding: 3px 15px;
			text-align: right;
			margin: 0 0 0 2em;
      border: solid 1px #2A3854;
      border-radius: 25px;
		}
	}
}
</style>