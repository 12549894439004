<template>
  <div id="header">
    <div id="logo" class="fade-in">
      <a href="/" title="ReSeT">
        <img src="./assets/logo.svg" alt="">
      </a>
    </div>
    <Nav :isMobile="isMobile" />
  </div>
  <router-view v-slot="{Component}" :fadeIn="fadeIn" :people="people">
    <transition name="fade">
        <component :is="Component" />
      </transition> 
  </router-view>
  <div id="footer" class="fade-in">
    <h3>Would you like to know more?<br/>
      We would love to hear from you</h3>
    <router-link class="shadow invert" to="/contact">Contact us</router-link>
  </div>
</template>

<script>
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Nav from '@/components/Nav';

export default {
  name: 'App',
  components: { 
    Nav
  },
  data() {
    return {
      blue: '#2A3854',
      transition: "",
      componentKey: 0,
      people : {
        "Charlotte" : {
          name: "Charlotte Calkin",
          position: "CEO",
          img: require("./assets/imgs/staff/charlotte-min.png"),
          bio: `Charlotte is Director of REF. She has been working in the fields of communication and restorative justice for many years. Charlotte is an accredited practitioner and trainer with the Restorative Justice Council (RJC). She has a Masters in Criminology from Cambridge and is just beginning an MA in Reconciliation and Peacebuilding. REF won the Howard League Award for Restorative Approaches in 2016.
          Since 2015 Charlotte has been championing the use of Restorative Practice (RP) in all sectors; statutory agencies, healthcare, education, third sector, the Criminal Justice System (CJS) and the workplace. She has helped bring about the introduction of RP in the executive workplace and her work with Aster led to them being the first organisation outside of the CJS to be awarded the RJC’s Quality Mark for Restorative Approaches. Charlotte currently works with Senior Leadership Teams and Boards to support alignment of the culture of the organisation within all the working relationships.

          Charlotte has delivered training since 2012 to 1000’s of individuals in a multitude of organisations including the Restorative Justice Council. Since 2010, Charlotte has facilitated a vast array of interventions on a broad range of cases ranging from complex workplace mergers through to formal enquiries with the IOPC and RJ in complex and sensitive cases. In 2017 Charlotte co-wrote the Guidelines for RJ in cases of sexual harm with the RJC. She works as a mentor and supervisor on many cases and for many organisations. In 2018 Charlotte presented workshops at the United Nations Crime Commission in Vienna on restorative practice with young people. Charlotte presented at the Justice Select Committee enquiry into restorative justice. She has also delivered a workshop on Restorative Practice at TEDGlobal 2017. Charlotte is frequently asked to speak at conferences and elsewhere on the site you will find links to some of her blogs and talks.`
        },
        "Jonathan" : {
          name: "Jonathan Pearl",
          position: "COO",
          img: require("./assets/imgs/staff/jonathan-min.png"),
          bio: `Jonathan Pearl is a (non-practicing) solicitor and mediator whose 30+ year career spans commercial private practice in the City to managing an international in-house corporate and compliance function of 100+ people for Sony.  Jonathan has extensive experience of litigating bet-the-company disputes in Europe, U.S. and Asia.  He has worked as a trusted senior adviser enabling top multinationals to understand & navigate legal and external political context; and to mitigate risk and realise opportunities.`
        },
        "Jane" : {
          name: "Jane Dring",
          position: "Managing director",
          img: require("./assets/imgs/staff/jane-min.png"),
          bio: `Jane delivers the ReSeT course in prisons. Jane is a qualified coach and level 2 accredited restorative justice practitioner.  Since 2010 Jane has been designing and delivering holistic employability and wellbeing courses working with a range of partners including local government, Essex Police, Adolescent Intervention and Prevention Teams, Community Rehabilitation Company and the National Probation Service.  Jane also works as a restorative justice practitioner for Essex Restorative and Mediation Service.This teamed with 17 years of corporate experience, the latter years working on a global level, enables Jane to excel in building relationships, creating trust and empowering clients to create change in their life.`
        },
        "Jacob" : {
          name: "Jacob Dunne",
          position: "Coordinator",
          img: require("./assets/imgs/staff/jacob-min.png"),
          bio: `Jacob has made our films and helped design the course. Jacob Dunne served fourteen months in prison following his conviction for manslaughter caused by a single punch on a night out in 2011. He developed a relationship with his victim’s parents through a Restorative Justice programme, and with their encouragement he has earned a first-class degree in criminology. He now spends his time mentoring and advising young people and prisoners on the perils of violence and how to turn their lives around. He has delivered TED talks and lectured at Cambridge University. He recently presented a high acclaimed podcast about his story called The Punch for BBC Sounds. Jonathan is a CEDR-Accredited Mediator (2002) and a Member of the Civil Mediation Council, whose knowledge and skills uniquely qualify him for a wide range of mediation and conflict resolution scenarios.`
        },
        "Lucy" : {
          name: "Lucy Willmott",
          position: "Project Manager",
          img: require("./assets/imgs/staff/lucy-min.png"),
          bio: `Lucy helped us to develop the course and has delivered the initial research. She is based at the University of Cambridge Criminology Department where she lectures on the MSt programme. She has over two decades of research experience, including applied research in prisons and secure hospitals. Her most recent book is "The honest politician’s guide to prisons and probation”  written in collaboration with Roy King. Lucy is a forensic psychologist. She has taught and supervised students on the Diploma and M.St. in Applied Criminology, at the Institute of Criminology, since 2009, joining the Institute as a teaching associate in 2012. Her specific areas of interest include the treatment and management of personality disordered patients and prisoners, the study of order and legitimacy in forensic settings, staff-prisoner/ patient relationships, the risk assessment of offenders, experimental and qualitative research methodologies, as well as a developing interest in the work of probation inspired by supervising on the M.St`
        }
      }
    }
  },
  created(){
    document.title = "ReSeT"
  },
  mounted() {
    console.log("%cCreated with ❤️ by Oscar Beamish and Yamen Albadin oscar-beamish.web.app \nyamengfx.com\n", "font-size: 0.8rem; color: white; background-color:darkred")
  },
  computed: {
    isMobile: function() {
      if(navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
      ){
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    fadeIn(){
      let elements = gsap.utils.toArray('.fade-in')
      elements.forEach((el) => {
      
        gsap.to(el, {
          opacity: 1,
          y: 0,
          duration: 0.75,
          scrollTrigger: {
            trigger: el,
            start: "top 75%",
            invalidateOnRefresh: true,
            // scrub: 0.1,
            end: "bottom bottom",

          }
        })
      })
    },
    imageZoom(){
      let elements = gsap.utils.toArray('.image-zoom')

      elements.forEach((el) => {
      
        gsap.to(el, {
          scale: 1.0333,
          duration: 0.75 * 1.75,
          transformOrigin: '50% 50%',
          ease: 'power1.out',
          scrollTrigger: {
            trigger: el,
            start: "top 75%",
            invalidateOnRefresh: true,
            // scrub: 0.1,
            end: "bottom bottom",

          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
// @import './css/app.scss';

#app {
  font-family: $bodySansFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#header {
  display: flex;
  padding: 1em 3% 0 3%;
  justify-content: space-between;
  height: 60px;
  width: 94%;
  align-items: center;
  top: 0;
  transition: all 0.4s ease;
  z-index: 100;

  #logo {
    width: 100px;
    z-index: 11;
  }

  #nav {
    display: flex;
    z-index: 11;
  }
}

#footer {
  @include for-phone-only(){
    padding: 2em 1em;
    flex-direction: column;
  }
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8vh 8% 16vh 8%;
  margin: 0;

  h3 {
    @include for-phone-only(){
      width: 100%;
    }
    font-weight: 600;
    margin: auto 0;
    width: 60%;
  }

  a {
    @include for-phone-only(){
      margin: 2em 0;
    }
    padding: 14px 48px;
    margin: 0 0.6em;
    border: solid 1px #2A3854;
    border-radius: 25px;
    display: block;
    transform: translateZ(0);
  }
}

//Router transitions
.slideUp-enter-active, .slideUp-leave-active {
  transition: all 1s ease;
}
.slideUp-enter-active {
    transition-delay: 0s;
}
.slideUp-enter {
  transform: translateY(100vh);
}
.slideUp-enter-to, .slideUp-leave {
  transform: translateY(0);
}
.slideUp-leave-to {
  transform: translateY(-100vh);
}
.slideDown-enter-active, .slideDown-leave-active {
  transition: all 1s ease;
}
.slideDown-enter-active {
    transition-delay: 0s;
}
.slideDown-enter {
  transform: translateY(-100vh);
}
.slideDown-enter-to, .slideDown-leave {
  transform: translateY(0);
}
.slideDown-leave-to {
  transform: translateY(100vh);
}
.slideRight-enter-active, .slideRight-leave-active {
  transition: all 1s ease;
}
.slideRight-enter-active {
    transition-delay: 0s;
}
.slideRight-enter {
  transform: translateX(-100vh);
}
.slideRight-enter-to, .slideRight-leave {
  transform: translateX(0);
}
.slideRight-leave-to {
  transform: translateX(100vh);
  opacity: 0;
}
.slideLeft-enter-active, .slideLeft-leave-active {
  transition: all 1s ease;
}
.slideLeft-enter-active {
    transition-delay: 0s;
}
.slideLeft-enter {
  transform: translateX(100vh);
}
.slideLeft-enter-to, .slideLeft-leave {
  transform: translateX(0);
}
.slideLeft-leave-to {
  transform: translateX(-100vh);
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.65s ease;
}

.fade-leave-active {
  transition: all 0.45s ease;
}

.fade-enter-active {
    transition-delay: 0.25s;
}
.fade-enter-to, .fade-leave {
  opacity: 1;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
