import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Sectors from '../views/Sectors.vue'
import Account from '../views/Account.vue'
import SignUp from '../views/SignUp.vue'
import SignIn from '../views/SignIn.vue'
import ForgotPassword from '../views/ForgotPassword';
import Contact from '../views/Contact.vue'
import { getCurrentUser } from '../firebase'
import { firebaseAuth } from "../firebase";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sectors',
    name: 'Sectors',
    component: Sectors,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      guestOnly: true,
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter(to, from, next) {
      const user = firebaseAuth.currentUser;
      if (user) {
        next({ name: "dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash,
            behavior: 'smooth' })
        }, 500)
      })
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isGuestOnly = to.matched.some(record => record.meta.guestOnly)

  const currentUser = await getCurrentUser()

  if (requiresAuth && !currentUser) {
    next('/signin')
  } else if (isGuestOnly && currentUser) {
    next('/account')
  } else {
    next()
  }
})

export default router
