<template>
  <div id="home"  @keydown.esc="closeModal" tabindex="0">
    <section id="hero">
      <div class="image-container">
        <img class="image-zoom" src="../assets/imgs/conference-image-2-graded.jpg" alt="">
      </div>
      
      <div id="hero-content">
        <div @click="heroButton" id="hero-text" class="shadow">
          <h1>
            Relational Skills Training
          </h1>
          <h5>ReSeT is a 6 weeks relational skills training course that is accessible to everyone through short films and workbooks. </h5><h5>The course teaches simple, practical skills and techniques that everyone can use to better express themselves and create better cultures.</h5>
        </div>
        <button @click="heroButton" class="shadow">Learn more</button>
      </div>
    </section>

    <section id="sub-hero">
      <h2>ReSeT for different sectors</h2>
      <div class="three-column">
        
        <router-link class="card shadow fade-in" to="/sectors#prisons">
          <div class="image-container">
            <img class="image-zoom" src="../assets/imgs/services/prisons.jpg" alt="prisons">
          </div>
          <h5>ReSeT for Prisons</h5>
          <p>ReSeT for the Criminal Justice System can be used in prisons or in probation.
            It is a 6-week course with a workbook and films.
          </p><p>The course can be delivered without external facilitators and we can offer the training to
            officers or professionals within the jail to deliver the course successfully.</p>
        </router-link>
        <router-link class="card shadow fade-in" to="/sectors#workplace">
          <div class="image-container">
            <img class="image-zoom" src="../assets/imgs/services/workplace.jpg" alt="workplace">
          </div>
          <h5>ReSeT for Workplace</h5>
          <p>This simple course offers online films and notes to give co-workers the skills to face into
            challenging conversations.
            Many organisations, particularly EDI and HR departments, contact us because they want to
            change their approaches to dealing with difficulty, issues and challenges. </p><p>It is essential that
            all co-workers understand restorative ways of working in all their interactions with
            colleagues.
          </p>
        </router-link>
        <router-link class="card shadow fade-in" to="/sectors#education">
          <div class="image-container">
            <img class="image-zoom" src="../assets/imgs/services/education.jpg" alt="education">
          </div>
          <h5>ReSeT for Education</h5>
          <p>Many schools and higher education establishments are introducing restorative practices to
            support the working environment.</p><p> Indeed, in some higher education establishments
            restorative/relational skills are being taught as part of induction.
          </p>
        </router-link>
      </div>
    </section>

    <section id="highlight" class="fade-in">
      <!-- <div class="image-container">
        <img src="../assets/imgs/placeholder.svg" alt="">
      </div> -->
      <div class="content">
        <h2>Learn about Everything that ReSeT has to offer</h2>
        <p>ReSeT takes all the principles of our years of experience in helping organisations create better cultures through better conversations.
          </p><p>We have now turned that experience into a 6-week course that is accessible for everyone.
          </p><p>The course supports any establishment or organisation that would like to improve their working environments through better relationships.
          </p><p>It offers simple tools that can be instantly used for more positive dialogue.</p>
          <router-link class="shadow button invert" to="/signin">Sign In</router-link>
      </div>
    </section>

    <section id="team-section" class="fade-in">
      <h2>Meet the team</h2>
      <div id="team">
        <div class="card people" v-for="person in people" :key="person.id" @click="personBio">
          <div class="image-container">
            <img class="image-zoom" :src="person.img" :alt="person.name">
          </div>
          <h6 class="name">{{person.name}}</h6>
          <p class="position">{{person.position}}</p>
        </div>
      </div>
    </section>

    <section id="partners-section" class="fade-in">
      <h2>Our Partners</h2>
      <div id="partners">
        <div class="logo">
          <img src="../assets/imgs/partner-logos/Pact.png" alt="">
        </div>
        <div class="logo">
          <img src="../assets/imgs/partner-logos/cja.svg" alt="">
        </div>
        <div class="logo">
          <img src="../assets/imgs/partner-logos/porticus.svg" alt="">
        </div>
      </div>
    </section>
    <modal class="person-details" v-if="showBio" :person="selectedPerson" @close="closeModal"/>
  </div>
</template>

<script>
import { ScrollTrigger } from "gsap/ScrollTrigger";
import modal from "../components/Modal.vue"

export default {
  name: 'Home',
  components: {
    modal,
  },
  props: [
    'fadeIn',
    'imageZoom',
    'people'
  ],
  data() {
    return {
      showBio: false,
      selectedPerson: {}
    }
  },
  mounted(){
      this.fadeIn();
      this.$root.imageZoom();
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 1000)
  },
  methods: {
    heroButton(){
      let subHero = document.getElementById("sub-hero")
      subHero.scrollIntoView({behavior: "smooth"});
    },
    personBio(e){
      this.showBio = true;
      document.body.style.overflow = "hidden"
      let peopleArray = Object.values(document.querySelectorAll('.people'))
      let index = peopleArray.indexOf(e.currentTarget)
      this.selectedPerson = Object.values(this.people)[index]
    },
    closeModal() {
      this.showBio = false;
      document.body.style.overflow = "initial"
    }
  }
}
</script>

<style lang="scss" scoped>
#home{
  outline: none;
}
#hero {
  @include for-phone-only(){
    flex-direction: column;
    padding: 1em 0;
    align-items: center;
  }
  padding: 4em 5% 0 5%;
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 60px - 4em);
  overflow: hidden;

  .image-container {
    @include for-phone-only(){
      max-width: 100%;
      align-self: center;
      overflow: auto;
    }
      max-width: 85%;
      max-height: 100%;
      align-self: flex-start;
      overflow: hidden;

      img {
        margin-left: auto;
        height: 100%;
      }
      z-index: 0;
  }

  #hero-content {
    @include for-phone-only(){
      order: initial;
      margin: 1em 0 0 0;
      width: 96%;
      position: absolute;
    }
    position: relative;
    order: -1;
    margin: 0 -25em 3em 0;
    z-index: 10;

    #hero-text {
      @include for-phone-only(){
        padding: 2em 1em;
      }
      @include for-tablet-landscape-only(){
        padding: 2em 1em;
      }
      padding: 3em 4em;
      background: #2A3854;
      color: #fff;

      h1 {
        @include for-phone-only(){
          white-space: initial;
        }
        white-space: nowrap;
        color: #fff;
      }

      h5 {
        color: #fff;
      }
    }

    button {
      @include for-phone-only(){
      margin: 1em auto;
      }
      padding: 1em 2.5em;
      margin: 1em 0;
      border-radius: 25px;
      border: none;
      color: #ffffff;
      background: #2A3854;
    }
  }
}
#sub-hero{
    @include for-phone-only(){
      padding: 2em 1em;
    }
  padding: 4em 8%;

  .three-column{
    @include for-phone-only(){
      flex-direction: column;
      padding: 2em 0;
    }
    @include for-tablet-portait-only(){
      flex-direction: column;
      padding: 2em 0;
    }
    display: flex;
    padding: 2em 5%;

    .card {
      @include for-phone-only(){
        margin: 0 0 1em 0;
        width: calc(100% - 2em);
      }
      @include for-tablet-portait-only(){
        margin: 0 0 1em 0;
        width: calc(100% - 2em);
      }
      margin: 0 1em 0 0;
      padding: 1em;
      border: solid 0.5px #ddd;
      width: 30%;

      .image-container {
        overflow: hidden;
        width: 100%;
        margin: 0 0 2em 0;
      }

      img {
        width: 100%;
      }

      &:last-child{
        margin: 0;
      }
    }
  }
}
#highlight{
  @include for-phone-only(){
  flex-direction: column;
      padding: 2em 1em;
  }
  padding: 4em 8%;
  background: #2A3854;
  color: #fff;
  display: flex;
  align-items: center;

  .image-container {
    @include for-phone-only(){
      width: 100%;
      margin: 0 auto;
      }
      width: 50%;
      margin: 0 1em 0 0;
  }

  img {
    width: 100%;
  }
  .content{
    @include for-phone-only(){
    width: 100%;
    margin: 1em 0 0 0;
    }
    width: 50%;
    margin: auto;
    text-align: center;

    h2 {
      color: #fff;
      text-align: center;
    }

    p {
      color: #fff;
      text-align: center;
    }

    a {
      display: inline-block;
      margin: 2em auto 0 auto;
    }
  }
}
#team-section{
  @include for-phone-only(){
    padding: 2em 1em;
  }
  padding: 5em 8% 3em 8%;

  h2 {
    @include for-phone-only(){
      margin: 0 0 35px 0;
    }
  }

  #team {
    display: flex;
    flex-wrap: wrap;

    .card {
      @include for-phone-only(){
        width: 100%;
        margin: 0 0 1em 0;
        padding: 0;
      }
      @include for-tablet-portait-only(){
        width: 100%;
        margin: 0 0 1em 0;
        padding: 0;
      }
      width: calc(25% - 4em);
      // margin: 0 1em 0 0;
      padding: 2em;
      text-align: center;
      cursor: pointer;

      .image-container {
        width: 50%;
        border-radius: 50%;
        margin: 0 auto 2em auto;
        overflow: hidden;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
#partners-section{
  @include for-phone-only(){
    padding: 2em 1em;
  }
  padding: 3em 8%;

  #partners{
    @include for-phone-only(){
      margin: 0 0 1em 0;
      padding: 0;
    }
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .logo {
      @include for-phone-only(){
        width: 100%;
        margin: 0 0 1em 0;
        padding: 0;
      }
      width: 30%;
      padding: 1em;
      display: flex; 
      align-items: center; 
      justify-content: center;

      img {
        @include for-phone-only(){
          width: 80%;
        }
        width: 60%;
      }
    }
  }
}
</style>
